import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import ClientApplicants from 'components/Clients/ClientApplicants';
import { Layout } from 'components/Layout';
import { CREATE_CLIENTS_BREADCRUMBS } from 'common/data/beadcrumbs';
import { FormikProvider, useFormik } from 'formik';
import { ClientTabs } from 'components/Clients/ClientTabs';
import { ClientNavBar } from 'components/Clients/ClientNavBar';
import {
  createClient,
  getClientInfo,
  getIndividualInfo,
  updateClient,
} from 'store/clients/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  convertClientToAPI,
  convertIndividualFromApi,
  convertOrganizationFromApi,
} from 'helpers/converters';
import {
  ClientMainInfoCard,
  ClientContactsCard,
  ClientAddressCard,
  ClientOptionsCard,
} from 'components/Clients/ClientCards';
import { HeadControlButtons } from 'components/HeadControllButtons/HeadControllButtons';
import { CLIENT_VALIDATOR } from 'forms/validators';

const initialValues = {
  mainInfo: {},
  clientOptions: {
    managerViewPrice: false,
    employeeViewPrice: false,
    employeeCreateOrder: false,
  },
  contacts: {
    email: '',
    phone: '',
  },
  address: undefined,
  clientType: null,
};

const TabKeys = {
  requisites: 'requisites',
  applicants: 'applicants',
};

export const getClientsState = state => state.clients;

const Client = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { search, pathname } = useLocation();
  const { client_type, id } = useParams();
  const clients = useSelector(getClientsState);
  const { client } = clients;
  const { isSubmitting } = client ?? {};

  const handleSubmit = values => {
    const convertedClient = convertClientToAPI(values);

    if (!id) {
      dispatch(createClient({ values: convertedClient, push }));
      return;
    }

    dispatch(updateClient({ values: convertedClient }));
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: CLIENT_VALIDATOR,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (id && client_type && client_type === 'individual') {
      dispatch(getIndividualInfo(id));
    }

    if (id && client_type && client_type === 'legal') {
      dispatch(getClientInfo(id));
    }
  }, [client_type, id]);

  useEffect(() => {
    const isCurrentId = id && Number(client?.details?.id) === Number(id);

    if (client_type === 'individual' && isCurrentId) {
      formik.resetForm({
        values: {
          ...convertIndividualFromApi(client.details),
        },
      });
    }

    if (client_type === 'legal' && isCurrentId) {
      formik.resetForm({
        values: {
          ...convertOrganizationFromApi(client.details),
        },
      });
    }
  }, [client, id]);

  const searchParams = new URLSearchParams(search);
  const activeTabKey = searchParams.get('tab');

  const handleSetTab = tab => {
    push({
      pathname: pathname,
      search: `?tab=${tab}`,
    });
  };

  return (
    <FormikProvider value={formik}>
      <Layout
        isScrollableHeader
        title={id ? 'client.details' : 'client.create'}
        breadcrumbs={CREATE_CLIENTS_BREADCRUMBS}
        extraContent={
          (activeTabKey ?? TabKeys.requisites) === TabKeys.requisites && (
            <HeadControlButtons
              typeCancel="gray"
              handleSubmit={formik.handleSubmit}
              path="/clients"
              isDisabledButton={!formik.dirty || isSubmitting}
            />
          )
        }
      >
        <Row className="p-0 g-0 h-100">
          <div
            className="position-sticky"
            style={{ marginBottom: '12px', top: 95 }}
          >
            <ClientTabs
              activeTab={activeTabKey ?? TabKeys.requisites}
              setActiveTab={handleSetTab}
            />
          </div>

          {(activeTabKey ?? TabKeys.requisites) === TabKeys.requisites ? (
            <>
              <Col xs={3} className="p-0">
                <ClientNavBar />
              </Col>

              <Col xs={9} className="ps-4">
                <ClientMainInfoCard />

                <ClientContactsCard />

                <ClientAddressCard />

                {formik.values.clientType === 'legal' && <ClientOptionsCard />}
              </Col>
            </>
          ) : (
            <ClientApplicants />
          )}
        </Row>
      </Layout>
    </FormikProvider>
  );
};

export default Client;
