import {
  APPLICANTS_BREADCRUMBS,
  EMPLOYEE_BREADCRUMBS,
} from 'common/data/beadcrumbs';
import { NavBar } from 'components/Common/NavBar';
import { HeadControlButtons } from 'components/HeadControllButtons/HeadControllButtons';
import { FormikProvider, useFormik } from 'formik';
import { APPLICANT_VALIDATOR } from 'forms/validators';
import { createApplicant } from 'helpers/api-requests/manager';
import {
  convertApplicantFromApi,
  convertApplicantManagerToApi,
} from 'helpers/converters';
import { isUserEmployee, isUserManager } from 'helpers/jwt_helper';
import { showToastError, showToastSuccess } from 'helpers/utils/toast';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getApplicant, updateApplicant } from 'store/applicants/actions';
import { ApplicantContactsCard } from './ApplicantContactsCars';
import { ApplicantDetailsCard } from './ApplicantDetailsCard';
// import { InviteButton } from './InviteButton';
import { ApplicantsPassportsCard } from './ApplicantsPassportsCard';
import { LayoutDetailPages } from 'hoc/LayoutDetailPages';
import { ModalConfirmDelete } from 'components/Common/ModalConfirmDelete';
import { StyledButtonGray } from 'components/Common';
import { StyledInput } from 'components/Common/FormikInput';
import { inviteUserWithMsgRequest } from 'helpers/api-requests/manager';

const getApplicantState = state => state.applicants.applicants.applicantDetail;

const applicantNav = [
  { title: 'common.information', id: 1, link: 'information' },
  { title: 'applicants.contacts', id: 2, link: 'contacts' },
  { title: 'applicants.passports', id: 3, link: 'passports' },
  // { title: 'applicants.visas', id: 4, link: 'visas' },
];

const getCurrentTitle = (
  firstName,
  lastName,
  id,
  isLoading,
  isUserEmployee,
) => {
  if (!id && !isUserEmployee) {
    return 'applicants.create';
  }

  if (firstName && lastName) {
    if (isLoading) {
      return '';
    } else {
      return `${firstName} ${lastName}`;
    }
  } else {
    return '';
  }
};

const initialValues = {
  userName: '',
  firstName: '',
  lastName: '',
  additionalName: '',
  birthday: null,
  email: '',
  passports: [
    {
      country: '',
      number: '',
      issue_date: null,
      end_date: null,
    },
  ],
  // visas: [{ country: '', started_at: null, ended_at: null }],
  sex: undefined,
  phone: '',
};

export const Applicant = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { push } = useHistory();
  const { applicant, isLoading, isSubmitting } = useSelector(getApplicantState);
  const { id } = useParams();

  const [isOpenInvitationModal, setIsOpenInvitationModal] = useState(false);
  const [invitationMessage, setInvitationMessage] = useState('');

  const handleSubmit = values => {
    if (id || isUserEmployee) {
      dispatch(
        updateApplicant({
          applicant: convertApplicantManagerToApi(values),
          id,
        }),
      );
      return;
    }

    createApplicant(convertApplicantManagerToApi(values))
      .then(response => {
        if (response.success) {
          push(`/applicants/${response.data.id}`);
          showToastSuccess(t('notifications.applicantCreated'));

          return;
        }
        showToastError(response.message);
      })
      .catch(error => showToastError(error));
  };

  const formik = useFormik({
    initialValues,
    validationSchema: APPLICANT_VALIDATOR,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (id || isUserEmployee) {
      dispatch(getApplicant(id));
    }
  }, [id]);

  const [inviteBtnTitle, setInviteBtnTitle] = useState();

  useEffect(() => {
    if (formik.values.passports?.length === 0 && formik.isSubmitting) {
      showToastError(t('client.passportRequired'));
    }
  }, [formik.isSubmitting, formik.values.passports]);

  useEffect(() => {
    if (applicant && ((id && applicant.id === Number(id)) || isUserEmployee)) {
      formik.resetForm({ values: convertApplicantFromApi(applicant) });
    }
  }, [id, applicant]);

  const handleCloseInvitationModal = () => {
    setInvitationMessage('');
    setIsOpenInvitationModal(false);
  };

  const onInvitationMessageChange = e => {
    setInvitationMessage(e.target.value);
  };

  const handleCompleteSendInvitationReq = () => {
    inviteUserWithMsgRequest(id, {
      message: invitationMessage,
    })
      .then(response => {
        if (response.success) {
          showToastSuccess(response.message);
          setInviteBtnTitle(t('invites.reInvite'));
          handleCloseInvitationModal();
        } else {
          showToastError(response.message);
        }
      })
      .catch(err => showToastError(err));
  };

  return (
    <FormikProvider value={formik}>
      <LayoutDetailPages
        navBarContent={
          <NavBar
            extraContent={
              // !applicant?.user?.last_login &&
              id &&
              isUserManager && (
                <StyledButtonGray
                  className="w-100 mt-4"
                  onClick={() => setIsOpenInvitationModal(true)}
                >
                  {inviteBtnTitle || applicant?.user?.is_invited
                    ? t('invites.reInvite')
                    : t('invites.invite')}
                </StyledButtonGray>
              )
            }
            scrollableHeader
            components={applicantNav}
          />
        }
        layoutConfig={{
          breadcrumbs: isUserEmployee
            ? EMPLOYEE_BREADCRUMBS
            : APPLICANTS_BREADCRUMBS,
          isScrollableHeader: true,
          loading: isLoading,
          title: getCurrentTitle(
            applicant?.first_name,
            applicant?.last_name,
            id,
            isLoading,
            isUserEmployee,
          ),
          extraContent: (
            <HeadControlButtons
              handleSubmit={formik.handleSubmit}
              isDisabledButton={!formik.dirty || isSubmitting}
              path="/applicants"
            />
          ),
        }}
      >
        <ApplicantDetailsCard />

        <ApplicantContactsCard />

        <ApplicantsPassportsCard />

        {/* <ApplicantsVisasCard /> */}
        {isOpenInvitationModal && (
          <ModalConfirmDelete
            handleCloseModal={handleCloseInvitationModal}
            handleDelete={handleCompleteSendInvitationReq}
            textHeader="invites.sendInvite"
            textBody={t('order.completeOrderInvitationMsgPlaceholder')}
            typeButtonConfirm="save"
            isOpenModal={isOpenInvitationModal}
            buttonText="common.send"
          >
            <StyledInput
              maxLength={1000}
              value={invitationMessage}
              type="textarea"
              $textarea={true}
              onChange={onInvitationMessageChange}
            />
          </ModalConfirmDelete>
        )}
      </LayoutDetailPages>
    </FormikProvider>
  );
};
